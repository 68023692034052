<template>
  <div>
    <div class="ant-modal-root order-detail_loader" v-if="loading">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin></a-spin>
        </div>
      </div>
    </div>
    <div v-if="!firstLoading">
      <div @scroll="isBottom" id="list-purchase">
        <div class="pr-0 justify-content-between mb-3">
          <div class="d-flex justify-content-start align-items-center mb-3">
            <div>
              <a-icon @click.prevent="$router.push({ path: '/account/retur' })" type="arrow-left"
                style="font-size: 20px; color: #000000; cursor: pointer" />
            </div>
            <div class="ml-3">
              <h4 class="mb-2">{{$t('menu.profile.retur.deposit.title')}}</h4>
            </div>
          </div>
        </div>

        <a-row :gutter="[24,24]" type="flex" class="mt-5 history-container">
          <a-col :md="9" >
            <div class="d-flex container-button-type align-items-center mb-4">
              <img src="@/resources/images/return.png" />
              <h4 class="mt-2 ml-2" style="color: #015CA1 !important">{{ $t('menu.retur.list.return_balance') }} Rp. {{balance}}</h4>
            </div>
            <div v-for="({ balance, seller_id, name }) in listBalance" :key="seller_id">
              <div class="d-flex container-button-type gap-12 justify-content-between align-items-center return-deposit-card">
                <div class="d-flex pl-3 rounded">
                  <WarehousePinIcon width="30" height="30" color="var(--biz-secondary-100)"/>
                  <div class=" ml-2">
                    <div class="store-name">{{ name }}</div>
                    <div class="store-return">
                      Rp. {{Intl.NumberFormat('en-US').format(balance)}}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center pr-3">
                  <button
                    class="btn-history"
                    size="large"
                    @click="handleSelectHistory({ seller_id, name })"
                    v-if="(seller_id !== selectedReturn.seller_id)"
                  >
                    {{ $t('menu.profile.retur.deposit.history') }}
                  </button>
                  <button
                    class="btn-close"
                    size="large"
                    @click="handleSelectHistory({})"
                    v-else
                  >
                    {{ $t('menu.profile.retur.deposit.close') }}
                  </button>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :md="15" :order="4">
            <div v-if="selectedReturn.name">
              <div>
                <div class="d-flex rounded">
                  <WarehousePinIcon width="30" height="30" color="var(--biz-secondary-100)"/>
                  <div class=" ml-2">
                    <div class="store-name">{{ selectedReturn.name }}</div>
                  </div>
                </div>
              </div>
              <div class="date-range-title">{{ $t('menu.profile.retur.deposit.date_range') }}</div>
              <a-space direction="vertical" :size="24">
                <a-range-picker
                  :value="dateValue"
                  :disabled-date="disabledDate"
                  @change="handleDateHistory"
                  @calendarChange="onCalendarChange"
                  style="width: 100%"
                  :format="'dddd DD MMMM YYYY'"
                />
              </a-space>
              <div v-if="historyReturn.length === 0" class="empty-history">
                <img src="@/resources/images/empty-history.png" />
                <div>{{ $t('menu.profile.retur.deposit.history_empty_info') }}</div>
              </div>
              <div v-else class="history-return-container">
                <div v-for="({ data, date }) in historyReturn" :key="date">
                  <div class="title-date">{{ date }}</div>
                  <div v-for="({ amount, typeDeposit, hour, type, id, isGetReturn }) in data" :key="id" class="card-return-price">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <img :src="require(`../../../../resources/images/deposit-${isGetReturn ? 'masuk' : 'keluar'}.png`)"/>
                        <div class="ml-2">
                          <div v-if="isGetReturn" class="return-type">
                            {{ $t('menu.profile.retur.deposit.deposit_return') }}
                          </div>
                          <div v-else class="return-type">
                            {{ $t('menu.profile.retur.deposit.deposit_bill') }}
                          </div>
                          <div class="return-hour">
                            {{`${$t(`menu.profile.retur.deposit.${typeDeposit}`)} &#x2022; ${hour}`}}
                          </div>
                        </div>
                      </div>
                      <div class="return-amount">
                        {{`${isGetReturn ? '+' : ''} ${Intl.NumberFormat('en-US').format(amount)}`}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a-pagination
                :current="page"
                :pageSize="limit"
                :total="total"
                v-if="historyReturn.length > 0"
                @change="onChangePagination"
                style="text-align: right;"
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import EmptyIcon from '@/components/Icons/EmptyRetur.vue'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'

export default {
  name: 'component-purchase-index',
  components: {
    EmptyIcon,
    WarehousePinIcon,
  },
  data: function () {
    return {
      data: [],
      page: 1,
      loading: false,
      activeKey: 0,
      modalVisible: false,
      confirmedStatusById: '',
      total_waiting_payment: 0,
      limit: 20,
      total: 0,
      modalSaldo: false,
      modalDistributor: false,
      selectedDistributor: null,
      images_url: {},
      balance: 0,
      listBalance: [],
      dateValue: null,
      selectedReturn: {},
      historyReturn: [],
      dates: null,
      firstLoading: true,
    }
  },
  computed: {
    ...mapState(['purchase', 'apps',]),
  },
  methods: {
    onCalendarChange (val) {
      this.dates = val
    },
    disabledDate (current) {
      let maxDate = moment().endOf('day')
      let minDate = moment().subtract(30, 'days').endOf('day')
      if (this.dates && this.dates[0]) {
        if (moment(this.dates[0]).add(7, 'days') <= maxDate) {
          maxDate = moment(this.dates[0]).add(7, 'days')
        }
        if (moment(this.dates[0]).subtract(7, 'days') >= minDate) {
          minDate = moment(this.dates[0]).subtract(7, 'days')
        }
      }
      if (this.dates && this.dates[0] && this.dates[1]) {
        maxDate = moment().endOf('day')
      }
      if (this.dates && this.dates[0] && this.dates[1]) {
        maxDate = moment().endOf('day')
        minDate = moment().subtract(30, 'days').endOf('day')
      }

      return current && (current > maxDate || current < minDate);
    },
    handleSelectHistory(payload) {
      this.selectedReturn = payload
      this.historyReturn = []
      this.dateValue = null
      this.page = 1
    },
    handleDateHistory(val) {
      this.loading = true
      this.dateValue = val
      this.page = 1
      this.handleGetHistory()
    },
    onChangePagination (page, limit) {
      this.page = page
      this.limit = limit
      this.handleGetHistory()
    },
    handleGetHistory() {
      this.$store.dispatch('retur/GETRETURHISTORY', {
        seller_id: this.selectedReturn.seller_id,
        page: this.page,
        limit: this.limit,
        start_date: moment(this.dateValue[0]).startOf('day').valueOf(),
        end_date: moment(this.dateValue[1]).endOf('day').valueOf(),
      })
        .then((data) => {
          moment.locale('id');
          let histories = {}
          let date = ''
          this.total = data.data.total_elements
          data.data.contents.forEach((data) => {
            // const dateHistory =  moment(data.created_at).format('dddd DD MMM YYYY')
            const dateHistory =  moment(data.created_at).format('YYYY-MM-DD')
            if (histories[dateHistory]) {
              histories[dateHistory] = [...histories[dateHistory], data]
            } else {
              histories[dateHistory] = [data]
            }
          })
          let historyReturn = []
          for (var key in histories) {
            if (histories.hasOwnProperty(key)) {
              historyReturn.push({
                date: moment(histories[key][0].created_at).format('dddd, DD MMM YYYY'),
                data: histories[key].map(history => {
                  let typeDeposit = ""
                  let isGetReturn = true
                  if (history.type === "DEPOSIT_RETURN") {
                    typeDeposit = "deposit_return_sub"
                  } else if (history.type === "BILL_PAID") {
                    if (history.normal_balance === 'D') {
                      typeDeposit = "bill_paid"
                    } else {
                      typeDeposit = "reversal_cancel_order"
                    }
                  } else if (history.type === "TRANSACTION_DEDUCTION") {
                    if (history.normal_balance === 'D') {
                      typeDeposit = "transaction_deduction"
                    } else {
                      typeDeposit = "reversal_bill_paid"
                    }
                  }

                  if (history.normal_balance === 'D') {
                    isGetReturn = false
                  } else {
                    isGetReturn = true
                  }
                  return {
                    ...history,
                    hour: moment(history.created_at).format('HH:mm'),
                    typeDeposit,
                    isGetReturn,
                  }
                }),
              })
            }
          }
          this.historyReturn = historyReturn
          this.loading = false
        })
    },
    changeType(key) {
      this.activeKey = key
      this.page = 1
      this.limit = 20
      this.fetchRetur(getValueByKey?.value)
    },
    openModal(id) {
      this.confirmedStatusById = id
      this.modalVisible = true
    },
    handleModalSaldo() {
      this.modalSaldo = !this.modalSaldo
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    fetchBalanceReturn() {
      this.loading = true
      this.$store.dispatch('retur/GETBALANCERETURN', {})
        .then((data) => {
          this.firstLoading = false
          let balance = 0
          let sellerIdDistri = []
          let listBalance = data.data
          data.data.map((item) => {
            balance += item.balance
            sellerIdDistri.push(item.seller_id)
          })
          this.balance = Intl.NumberFormat('en-US').format(balance)
          this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
            channel_id: this.$store.state.user.user_data.channel_id,
            seller_id: sellerIdDistri.length > 0 ? sellerIdDistri : this.$store.state.user.user_data.seller.map((item) => item.id)
          })
            .then(({ data }) => {
              this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
                id: data.map((item) => item.warehouse_id),
              })
                .then(({ data }) => {
                  this.listDistributor = data
                  this.listBalance = listBalance.map((balance) => {
                    const sellerIdDetail = data.find((obj) => obj.seller_id === balance.seller_id)
                    return {
                      ...balance,
                      ...sellerIdDetail,
                    }
                  })
                  this.loading = false
                })
                .catch(err => {
                  this.loading = false
                  console.log(err)
                })
            })
        })
        .catch((err) => {
          this.loading = false
        })
    },
    isBottom() {
      const el = document.querySelectorAll("#wrap")
      const navbarHeight = 151;
      const offsetElement = 5
      const lastEl = el[el.length - offsetElement]
      const bounds = lastEl.getBoundingClientRect().y
      const alreadyGetAllData = this.data.length >= this.total
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        const getValueByKey = this.listTab.find(({ key }) => key === this.activeKey)
        this.fetchRetur(getValueByKey?.value)
      }
    },
  },
  mounted() {
    this.fetchBalanceReturn()
    window.addEventListener("scroll", this.isBottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.isBottom);
  },
}
</script>

<style lang="scss">
.active {
  background: #E8F6FE;
  border: 1px solid #015CA1 !important;
  border-radius: 12px;
  color: #015CA1;
}

.inactive {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  color: #929292;
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;;
}

/////////
.return-deposit-card {
  padding: 15px 5px;
  border-radius: 8px;
  margin: 5px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.store-name {
  color: #767676;
  font-size: 18px;
}
.store-return {
  color: black;
  font-size: 16px;
  font-weight: 800;
}
.btn-history {
  width: 110px;
  background: #015289;
  border: 1px solid #015289;
  color: white;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #1b78b8;
    border: 1px solid #1b78b8;
    color: white;
  }
}
.btn-close {
  width: 110px;
  background: white;
  border: 1px solid #015289;
  color: #1b78b8;
  border-radius: 8px;
  padding: 7px 0;
  font-size: 14px;
  &:hover {
    background: #65b0e3;
    border: 1px solid #1b78b8;
    color: #1b78b8;
  }
}
.date-range-title {
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 8px;
  color: black;
  font-weight: 400;
}
.empty-history {
  text-align: center;
  font-size: 14px;
  color: #015CA1;
  margin: 35px 0;
  div {
    max-width: 300px;
    margin: auto;
    margin-top: 20px;
  }
}
.title-date {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 20px 0 15px 0;
}
.return-type {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.return-hour {
  font-size: 14px;
  font-weight: 400;
  color: #767676;
}
.card-return-price {
  margin: 10px 0;
}
.return-amount {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.history-return-container {
  margin: 20px 0;
  height: 500px;
  overflow: auto;
}
.history-container {
  min-height: 600px;
}
</style>
